import styled from "styled-components";

export const MainNavBar = styled.div`
  background-color: rgba(196, 196, 196, 0.5);
  width: 100%;
  min-height: 5.625rem;

  @media (min-width: 769px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1025px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const NavLinksUl = styled.ul`
  padding: 5px 5px;
  list-style: none;
  text-align: center;
  display: block;

  @media (min-width: 769px) {
    display: flex;
  }
`;

export const NavLinkItemLi = styled.li`
  margin: 1rem;
`;

export const NavLinkItemA = styled.a`
  color: #000000;
  padding: 0.5rem;
  text-align: center;

  &:hover {
    color: #ffffff;
  }
`;

export const LogoImg = styled.img`
  border-radius: 25px;
`;

export const LogoDiv = styled.div`
  display: block;
  text-align: center;
  padding: 5px 20px;
`;
