import { Background, Button, Container, Header, Paragraph } from "./styles";

const BackgroundContainer = () => {
  return (
    <Background style={{ backgroundSize: "400% 400%" }}>
      <Container>
        <Header>
          <span style={{ color: "navy" }}>C</span>al
          <span style={{ color: "navy" }}>C</span>lan
        </Header>
        <Paragraph>Connective accounting</Paragraph>
        <Paragraph>Coming soon!</Paragraph>
        <a href="mailto:hello@calclan.com">
          <Button>Contact us</Button>
        </a>
      </Container>
    </Background>
  );
};

export default BackgroundContainer;
