import {
  MainNavBar,
  NavLinksUl,
  LogoDiv,
  LogoImg,
  NavLinkItemLi,
  NavLinkItemA,
} from "./styles";

const LINKS: Array<{ key: number; value: string }> = [
  { key: 1, value: "About" },
  { key: 2, value: "Contact Us" },
];

const NavLinkItems = LINKS.map((link) => (
  <NavLinkItemLi key={link.key}>
    <NavLinkItemA>{link.value}</NavLinkItemA>
  </NavLinkItemLi>
));

const NavBar = () => {
  return (
    <MainNavBar>
      <LogoDiv>
        <LogoImg src="https://via.placeholder.com/60" alt="logo" />
      </LogoDiv>
      <NavLinksUl>{NavLinkItems}</NavLinksUl>
    </MainNavBar>
  );
};

export default NavBar;
