import { useState } from "react";

import { BackgroundContainer } from "../../components";

const MainPage = () => {
  const [toggle, setToggle] = useState(true);

  // const clickHandler = () => {
  //   setToggle(!toggle);
  // };
  return (
    <div>
      <BackgroundContainer />
      {/* <NavBar /> */}
      {/* <Header /> */}
      {/* <PrimaryButton clickHandler={clickHandler} toggle={toggle} /> */}
    </div>
  );
};

export default MainPage;
