import styled from "styled-components";

export const MainHeader = styled.header`
  background-color: #314380; /* Green */
  min-height: 43.125rem;
  margin-left: auto;
  margin-right: auto;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  min-height: inherit;
`;

export const TextDiv = styled.div`
  display: inline-block;
  max-width: 720px;
`;

export const ButtonDiv = styled.div`
  display: inline-block;
`;

export const TextA = styled.a`
  color: #000000;
  font-size: 36px;
`;
