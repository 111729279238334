import styled, { keyframes } from "styled-components";

export const breatheAnimation = keyframes`
0% {background-position: 0% 50%;}
50% {background-position: 100% 50%;}
100% {background-position: 0% 50%;}
`;

export const Background = styled.div`
  animation: 15s ease infinite;
  height: 100vh;
  /* background-size: 400% 400%; */
  animation-name: ${breatheAnimation};
  animation-duration: 15s;
  animation-iteration-count: infinite;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
`;

export const Header = styled.h1`
  color: white;
  font-size: 46px;
  margin-bottom: 0px;
`;
export const Paragraph = styled.p`
  margin-top: 0.5rem;
  color: white;
  font-size: 24px;
`;

export const Button = styled.button`
  border: 2px solid white;
  padding: 10px 20px;
  color: white;
  background: transparent;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-size: 20px;
  font-family: sans-serif;
  transition: all 0.5s;
  &:before {
    width: 100%;
    height: 100%;
    content: "";
    margin: auto;
    position: absolute;
    top: -100%;
    left: -100%;
    background: #212121;
    transition: all 0.5s;
    z-index: -1;
  }
`;
